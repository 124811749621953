<template>
    <section class="portal-gray">
        <div class="event-list">
            <div class="event-title bg-white">
                <div class="ctr d-flex align-items-center justify-content-between h-100">
                    <div class="event-header ">
                        {{ $t("TITLE.LIVE-EVENT") }}
                    </div>
                    <div class="divider"></div>
                </div>
            </div>
            <section class="p-3 bg-white" style="margin-top: 2px;">
                <div class="search-bar ctr">
                    <el-form :model="search" label-width="100px">
                        <el-row>
                            <el-col :span="24">
                                <front-fake-form-item :label="$t('TITLE.SUBJECT')" :width="100" thin>
                                    <el-input v-model="search.filter"
                                              :placeholder="$t('TITLE.SUBJECT')"
                                              type="text"
                                              size="small"
                                              clearable
                                              @keyup.prevent.native="debounced"
                                    >
                                    </el-input>
                                </front-fake-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <front-fake-form-item :label="$t('TITLE.SECTION')" :width="100" thin>
                                    <el-select v-model="search.section" clearable :placeholder="$t('TITLE.SECTION')"
                                               size="small" class="w-100" @change="reload">
                                        <el-option v-for="x in sections" :key="x.id" :value="x.id"
                                                   :label="en?x.english:x.name"></el-option>
                                    </el-select>
                                </front-fake-form-item>
                            </el-col>
                            <el-col :span="12">
                                <front-fake-form-item :label="$t('TITLE.SPEAKER')" :width="100" thin>
                                    <el-input v-model="search.speaker"
                                              :placeholder="$t('TITLE.SPEAKER')"
                                              type="text"
                                              size="small"
                                              clearable
                                              @keyup.prevent.native="debounced"
                                    >
                                    </el-input>
                                </front-fake-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <front-fake-form-item :label="$t('TITLE.DATE')" :width="100" thin>

                                    <el-radio-group class="mr-3 " v-model="search.date_index" size="small"
                                                    @change="handleDateGroupChange">
                                        <el-radio-button icon="el-icon-aim" label="1" class="m-0">
                                            {{ $t('TITLE.TODAY') }}
                                        </el-radio-button>
                                        <el-radio-button icon="el-icon-date" label="2" class="m-0">
                                            {{ $t('TITLE.1MONTH') }}
                                        </el-radio-button>
                                        <el-radio-button icon="el-icon-date" label="3" class="m-0">
                                            {{ $t('TITLE.6MONTH') }}
                                        </el-radio-button>
                                        <el-radio-button icon="el-icon-date" label="4" class="m-0">
                                            {{ $t('TITLE.1YEAR') }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </front-fake-form-item>
                            </el-col>
                            <el-col :span="12">
                                <front-fake-form-item :label="$t('TITLE.RANGE')" :width="100" thin>
                                    <el-date-picker v-model="search.dates"
                                                    :placeholder="$t('TITLE.DATE')"
                                                    type="daterange"
                                                    size="small"
                                                    value-format="yyyy-MM-dd"
                                                    clearable
                                                    @change="reload"
                                    >
                                    </el-date-picker>
                                </front-fake-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <front-fake-form-item label="" :width="100" thin>
                                <el-button type="warning" icon="el-icon-search" size="small" @click="reload">
                                    {{ $t('TITLE.SEARCH') }} {{ $t('TITLE.LIVE-EVENT') }}
                                </el-button>
                            </front-fake-form-item>
                        </el-row>
                    </el-form>
                </div>
            </section>

            <section class="ctr pb-5">
                <el-row :gutter="30" class="events-row-1">
                    <el-col>
                        <event-card v-for="item in events_result" :key="item.id" :event="item"
                                    style="width: 20%"
                                    :style="{height: (events_card_height / 2 - 10)+'px'}"
                                    class="others">
                        </event-card>
                    </el-col>
                </el-row>
            </section>
        </div>
    </section>
</template>
<script>


import "@/views/scss/portal.scss"
import CommonMixin from "@/common/mixins/common-mixin"
import EventCard from "@/components/event-card";
import FrontFakeFormItem from "@/components/front-fake-form-item";

export default {
    name: "live",
    components: {FrontFakeFormItem, EventCard},
    mixins: [CommonMixin],
    data() {
        return {
            carousel_height: 315,
            events_card_height: 590,
            search: {
                filter: "",
                dates: [],
                date_index: "4",
                section: 0,
            },
            sections: [{id: 0, name: '所有栏目', english: 'all sections'}],
            events_result: [],
            debounced: false,
        }
    },
    mounted() {
        const vm = this;
        window.addEventListener('resize', this.handleResize, false);
        this.handleDateGroupChange();
        this.handleResize();
        this.reload();
        this.debounced = this.$debounce(1000, () => {
            vm.reload();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize, false)
    },
    computed: {},
    methods: {
        reload() {
            this.$api("Admin.Portal.Live", this.search, true).then(res => {
                this.events_result = res.data.data.result;
                if (this.sections.length === 1) {
                    res.data.data.sections.forEach(i => {
                        this.sections.push(i);
                    })
                }
            });
        },
        handleResize() {
            if (document.body.clientWidth >= 1440) {
                this.carousel_height = 568.12
                this.events_card_height = 590
            } else if (document.body.clientWidth >= 1200) {
                this.carousel_height = 433
                this.events_card_height = 550
            } else {
                this.carousel_height = 315
                this.events_card_height = 487
            }
        },
        handleDateGroupChange() {
            const now = new Date();
            if (this.search.date_index === '1') {
                // today
                this.search.dates = [now.Format("yyyy-MM-dd"), now.Format("yyyy-MM-dd")];
            } else if (this.search.date_index === '2') {
                // month
                const second = new Date();
                second.setMonth(second.getMonth() + 1);
                this.search.dates = [now.Format("yyyy-MM-dd"), second.Format("yyyy-MM-dd")];
            } else if (this.search.date_index === '3') {
                // 6 month
                const second = new Date();
                second.setMonth(second.getMonth() + 6);
                this.search.dates = [now.Format("yyyy-MM-dd"), second.Format("yyyy-MM-dd")];
            } else if (this.search.date_index === '4') {
                // 6 month
                const second = new Date();
                second.setMonth(second.getMonth() + 12);
                this.search.dates = [now.Format("yyyy-MM-dd"), second.Format("yyyy-MM-dd")];
            }
            this.reload();
        },

    }
}
</script>
