<template>
    <el-col class="portal-calendar">
        <div class="header">
            <div>{{ today.getFullYear() }} {{ month_name[today.getMonth()] }}</div>
            <div>
                <div class="navigate mr-2" @click="handleCurrentMonth" v-if="!same_month">
                    <el-icon name="refresh"></el-icon>
                </div>
                <div class="navigate mr-2" @click="handlePreviousMonth">
                    <el-icon name="arrow-left"></el-icon>
                </div>
                <div class="navigate" @click="handleNextMonth">
                    <el-icon name="arrow-right"></el-icon>
                </div>
            </div>
        </div>
        <div class="week-name force-font-11">
            <div v-for="x in week_name" :key="x" class="item">{{ x }}</div>
        </div>
        <div class="week-days">
            <div v-for="x in days" :key="x.index" class="item" :class="calendar_background[x.today?1:0]">{{ x.date }}</div>
        </div>
    </el-col>
</template>

<script>

export default {
    name: "portal-calendar",
    data() {
        return {
            today: new Date(),
            same_month:false,
            month_name: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
                "Aug", "Sept", "Oct", "Nov", "Dec"],
            week_name: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            days: [],
            calendar_background: ['', 'active', 'has']
        }
    },
    mounted() {
        this.makeDays();
    },
    methods: {
        getMonthDays(date) {
            const year = date.getFullYear();
            const month = date.getMonth();
            let days = [31, 28, 31, 30, 31, 30, 31, 30, 30, 31, 30, 31]
            if ((year % 4 === 0) && (year % 100 !== 0 || year % 400 === 0)) {
                days[1] = 29
            }
            return days[month]
        },
        makeDays() {
            const first_of_month = this.today.getTime() - (this.today.getDate() - 1) * 1000 * 60 * 60 * 24;
            const first = new Date(first_of_month).getDay();
            const last = this.getMonthDays(this.today) + first - 1;
            this.days = [];
            const date = new Date();
            const current_date = date.getDate();
            this.same_month = this.today.getMonth() === date.getMonth();
            for (let i = 0; i <= last; i++) {
                if (i < first) this.days.push({index: i});
                else this.days.push({
                    index: i,
                    date: i - first + 1,
                    today: this.same_month && current_date  === i - first + 1
                });
            }
        },
        handlePreviousMonth(){
            this.today.setMonth(this.today.getMonth() -1);
            this.makeDays();
        },
        handleNextMonth(){
            this.today.setMonth(this.today.getMonth() +1);
            this.makeDays();
        },
        handleCurrentMonth() {
            this.today = new Date();
            this.makeDays();
        }
    }
}
</script>

<style lang="scss">
@import "~@/scss/global.scss";

.portal-calendar {
    font-size: 12px;
    color: #101010;

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        text-transform: Uppercase;

        .navigate {
            display: inline-block;
            transition: color ease-in 200ms;
            cursor: pointer;
            color: #101010;

            &:hover {
                color: $--public-highlight;
            }
        }
    }

    .week-name {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        margin-right: -5px;
        margin-bottom: 5px;
        font-weight: bold;

        .item {
            width: calc(14.28% - 5px);
            margin-right: 5px;
            text-align: center;
        }
    }

    .week-days {
        margin-right: -5px;

        .item {
            width: calc(14.28% - 5px);
            float: left;
            text-align: center;
            line-height: 22px;
            margin-bottom: 2px;
            margin-right: 5px;
            border: 1px solid transparent;
        }

        .active {
            color: $--public-highlight;
            font-weight:bold;
            border-color: $--public-highlight;
            //background-color:
            //border-radius: 25px;
        }

        .has {
            border-radius: 25px;
            background-color: $--public-default-color;
        }
    }

}
</style>
