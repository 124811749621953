<template>
    <div class="ctr">
        <section class="portal-carousel">
            <div class="flex-grow-1">
                <el-carousel trigger="click" :height="carousel_height+'px'"
                             ref="SHOW_REELS"
                             indicator-position="none"
                             @change="handleShowReelChange"
                >
                    <el-carousel-item v-for="item in show_reels"
                                      :key="item.id"
                                      class="carousel-box"
                                      :name="'car'+item.id">
                        <div class="scale">
                            <div class="item" v-lazy:background-image="item.picture">
                            </div>
                            <div class="description">
                                <a :href="item.url" target="_blank">
                                    <div class="time">{{ item.live ? "直播" : "" }} {{ item.event_time}}</div>
                                    <div class="title">{{ item.subject }}</div>
                                </a>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="flex-grow-0 newest" :style="{height:carousel_height+'px'}">
                <div class="title">{{ $t('TITLE.LATEST-EVENTS') }}</div>
                <div class="contents" id="EVENT_CONTAINER">
                    <ul>
                        <li v-for="item in show_reels" :key="item.id" :id="'EVENT_'+item.id"
                            :class="show_reels_index===item.id?'active':''"
                            @click="$refs['SHOW_REELS'].setActiveItem('car'+item.id)"
                        >
                            <div class="sprite"></div>
                            <div class="time">{{ item.event_time | str16 }}</div>
                            <div class="subject">{{ item.subject }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "portal-show-reels",
    data() {
        return {
            show_reels_index: 0,
            show_reels: null,
        }
    },
    props: {
        carousel_height: {
            type: Number, default() {
                return 0
            }
        }
    },
    mounted() {
        this.loadShowReels()
    },
    methods: {
        loadShowReels() {
            this.$api("Admin.Portal.ShowReels",{},1).then(res=>{
                this.show_reels = res.data.data;
                this.handleShowReelChange(0)
            })
        },
        handleShowReelChange(index) {
            if (this.show_reels) {
                this.show_reels_index = this.show_reels[index].id;

                // 判断是否要滚动到show reel到位置
                const one = document.getElementById("EVENT_" + this.show_reels_index);
                const container = document.getElementById("EVENT_CONTAINER");

                if (one && container) {
                    const frame_height = container.clientHeight;
                    const frame_top = container.scrollTop;
                    const one_top = one.offsetTop - 175;
                    const one_bottom = one.offsetHeight + one_top;
                    if (one_bottom > frame_height + frame_top) {
                        container.scrollTop = frame_top + one.offsetHeight + 2
                    } else if (one_top < frame_top) {
                        container.scrollTop = one_top;
                    }
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
